@import "./variables";

.main-dark-mode {
  background-color: $dark-blue !important;
  color: $light-grey !important;
  a {
    color: $light-grey;
    &:hover {
      color: rgba(255, 255, 255, 0.904);
    }
  }
}

.main-light-mode {
  background-color: $white !important;
  color: $dark-blue !important;
  a {
    color: $dark-blue;
    &:hover {
      color: contrast(40%);
    }
  }
}

.bg-main-dark-color {
  background-color: $dark-blue;
}
.bg-main-light-color {
  background-color: $white;
}

// header search box
.bg-search-main-dark {
  background: linear-gradient(180deg, $dark-grey -57.69%, rgba(40, 54, 72, 0) 184.62%);
  color: $light-grey !important;
}

.bg-search-main-light {
  background: linear-gradient(180deg, $white -57.69%, rgba(40, 54, 72, 0) 184.62%);
  color: $dark-blue;
}

.btn-navbar-main-dark {
  background: linear-gradient(180deg, $dark-grey -57.69%, rgba(40, 54, 72, 0) 184.62%);
  color: $light-grey !important;
  box-shadow: 0 0 0.3rem 0 rgb(42 45 50 / 25%);
  border-radius: 0.7rem;

  &:hover,
  &:active {
    filter: contrast(0.7);
  }
}

.btn-navbar-main-light {
  background: linear-gradient(180deg, $white -57.69%, rgba(40, 54, 72, 0) 184.62%);
  border: 0.1rem solid $dark-blue;
  border-radius: 0.7rem;
  &:hover,
  &:active {
    filter: contrast(0.7);
  }
}

.must-know-dropdown-light {
  color: $dark-blue;
  background: $white;
  color: $dark-blue;
  li {
    a {
      color: $dark-blue;
    }
  }
}

.must-know-dropdown-dark {
  color: $light-grey;
  background: rgba(26, 53, 72, 1);
  li {
    a {
      color: $light-grey;
    }
  }
}

.text-main-dark-color {
  color: $light-grey;
  &:hover {
    color: $light-grey;
    cursor: none;
  }
}

.text-main-light-color {
  color: $dark-blue;
  &:hover {
    color: $dark-blue;
    cursor: none;
  }
}

.rounded-main-dark {
  border: 0.1rem solid $light-grey;
  box-sizing: border-box;
  border-radius: 2rem;
  background-color: transparent;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: $light-grey;
    color: $dark-blue;
  }
}

.rounded-main-light {
  border: 0.1rem solid $dark-blue;
  box-sizing: border-box;
  border-radius: 2rem;
  background-color: transparent;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: $light-grey;
    color: $dark-blue;
  }
}

.text-black {
  color: $black;
}

.text-white {
  color: $white !important;
}

.text-red {
  color: $cranberry;
}

.bg-red {
  background-color: rgba(68, 6, 32, 1);
}

.text-yellow {
  color: $tulip;
}

.text-blue {
  color: $blue;
}

.text-light-grey {
  color: $light-grey;
}

.text-dark-slate-blue {
  color: $dark-slate-blue;
}
