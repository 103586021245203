@use "sass:math";

@mixin icon_size($width, $height) {
  width: $width + rem;
  height: $height + rem;
  background-size: $width + rem $height + rem !important;
}

.icon {
  width: 5rem;
  height: 5rem;
  background-size: 5rem 5rem !important;
  background-repeat: no-repeat !important;
  display: block;
}

// loop for generate classes from icon-1 to icon-60
@for $i from 1 through 80 {
  .icon-#{$i} {
    @include icon_size(#{math.div($i, 10)}, #{math.div($i, 10)});
  }
}

.icon-search {
  background-image: url("/assets/images/search-grey.svg");
}

.icon-chevron-down {
  background-image: url("/assets/images/chevron-down.svg");
}

.icon-add {
  background-image: url("/assets/images/add.svg");
}

.icon-news {
  background-image: url("/assets/images/bell.svg");
}

.icon-help {
  background-image: url("/assets/images/help-btn-inactive.svg");
}

.icon-logout {
  background-image: url("/assets/images/log-out.svg");
}

.icon-filters {
  background-image: url("/assets/images/filter-icon.svg");
}

.icon-arrow {
  background-image: url("/assets/images/arrow.svg");
}

.icon-star {
  background-image: url("/assets/images/star.svg");
}

.icon-dashboard {
  background-image: url("/assets/images/dashboard.svg");
}

.icon-report {
  background-image: url("/assets/images/report.svg");
}

.icon-sap {
  background-image: url("/assets/images/Power-BI-icon.svg");
}

.icon-bo {
  background-image: url("/assets/images/BO-icon.png");
}

.icon-favorites-white {
  background-image: url("/assets/images/favorites-white.svg");
}

.icon-last-viewed-white {
  background-image: url("/assets/images/last-viewed-white.svg");
}

.icon-favorites {
  background-image: url("/assets/images/favorites.svg");
}

.icon-last-viewed {
  background-image: url("/assets/images/last-viewed.svg");
}

.icon-back {
  background-image: url("/assets/images/back.svg");
}

.icon-print {
  background-image: url("/assets/images/print.svg");
}

.icon-warning {
  background-image: url("/assets/images/warning.svg");
}

.icon-export {
  background-image: url("/assets/images/export.svg");
}

.icon-share {
  background-image: url("/assets/images/share.svg");
}

.icon-tabs {
  background-image: url("/assets/images/tabs.svg");
}

.icon-full-screen {
  background-image: url("/assets/images/full-screen.svg");
}

.icon-close {
  background-image: url("/assets/images/close.svg");
}

.icon-close-sidebar {
  background-image: url("/assets/images/report-close-sidebar.svg");
}

.icon-settings {
  background-image: url("/assets/images/settings.svg");
}

.icon-polygon {
  background-image: url("/assets/images/polygon-up.svg");
}

.icon-polygon-down {
  background-image: url("/assets/images/polygon-down-grey.svg");
}

.icon-delete {
  background-image: url("/assets/images/delete.svg");

  &:hover {
    background-image: url("/assets/images/delete-active.svg");
  }
}

.icon-edit {
  background-image: url("/assets/images/edit.svg");

  &:hover {
    background-image: url("/assets/images/edit-active.svg");
  }
}

.icon-see-report {
  background-image: url("/assets/images/see-report.svg");

  &:hover {
    background-image: url("/assets/images/see-report-active.svg");
  }
}

.icon-connect-as {
  background-image: url("/assets/images/connect-as.svg");

  &:hover {
    background-image: url("/assets/images/connect-as-active.svg");
  }
}

.icon-see-right {
  background-image: url("/assets/images/see-rights.svg");

  &:hover {
    background-image: url("/assets/images/see-rights-active.svg");
  }
}

.icon-checkmark-black {
  background-image: url("/assets/images/checkmark-black.svg");
}

.icon-cross-white {
  background-image: url('/assets/images/cross-white.svg');
}

.icon-checked-circle-white {
  background-image: url('/assets/images/checked-circle-white.svg');
}

.icon-warning-white {
  background-image: url('/assets/images/cross-circle-white.svg');
}

.icon-folder {
  background-image: url('/assets/images/folder.svg');
}

.icon-file {
  background-image: url('/assets/images/file.svg');
}

.icon-pencil-blue {
  background-image: url('/assets/images/pencil-blue.svg');
}

.icon-done {
  background-image: url('/assets/images/done.svg');
}

.icon-export-pdf {
  background-image: url('/assets/images/export-pdf.svg');
}

.icon-export-png {
  background-image: url('/assets/images/export-png.svg');
}

.icon-export-ppt {
  background-image: url('/assets/images/export-ppt.svg');
}

.icon-export-excel {
  background-image: url('/assets/images/export-excel.svg');
}

.icon-favorite-circle {
  background-image: url("/assets/images/favorites-circle.svg");
}

.icon-news-table {
  background-image: url("/assets/images/news.svg");
}

.icon-incident {
  background-image: url("/assets/images/incident.svg");
}

.icon-cross-red {
  background-image: url('/assets/images/cross-red.svg');
}

.icon-world-wide {
  background-image: url('/assets/images/world-wide.svg');
}

.icon-alert-outline-light-red {
  background-image: url('/assets/images/alert-outline-light-red.svg');
}

.icon-alert-dark {
  background-image: url('/assets/images/alert-dark.svg');
}

.icon-file-grey {
  background-image: url('/assets/images/icon-file-green.svg');
}

.icon-link {
  background-image: url('/assets/images/link-file.svg');
}

.icon-input-close-dark {
  background-image: url("/assets/images/input-close.svg");
}