@use "sass:math";

// A mixin for writing @font-face rules in SASS.
@mixin font-face($name, $file, $weight, $style) {
  @font-face {
    font-family: "#{$name}";
    src: url("/assets/fonts/#{$file}.ttf");
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face(Helvetica-Bold, Helvetica-Bold, normal, normal);
@include font-face(Helvetica-Medium, Helvetica-Medium, normal, normal);
@include font-face(Helvetica-Regular, Helvetica-Regular, normal, normal);
@include font-face(Trenda-Bold, Trenda-Bold, normal, normal);
@include font-face(Trenda-Medium, Trenda-Medium, normal, normal);
@include font-face(Trenda-Regular, Trenda-Regular, normal, normal);


// loop for generate classes from font-size-1 to font-size-60
@for $i from 1 through 60 {
  .font-size-#{$i} {
    font-size: #{math.div($i,10)} + 'rem' !important;
  }
}

//  Apply font-family class to div / any semantic elements.
.trenda-regular {
  font-family: $trenda-normal;
}

.trenda-medium {
  font-family: $trenda-medium !important;
}

.trenda-bold {
  font-family: $trenda-bold;
}

.helvetica-regular {
  font-family: $helvetica-normal;
}

.helvetica-medium {
  font-family: $helvetica-medium;
}

.helvetica-bold {
  font-family: $helvetica-bold;
}
