.customPopClass {
  max-width: none;
  border: none;
  border-radius: 1rem;
  .popover-arrow {
    display: none;
  }
  .popover-body {
    padding: 0;
  }
}
app-add-user{
  .ng-select .ng-arrow {
    display: none !important;
  }
  .ng-select .ng-arrow-wrapper {
    height: 1.4rem;
    width: 1.4rem;
    background-image: url('/assets/images/search-grey.svg');
    background-repeat: no-repeat;
    margin-right: 1.2rem;
    margin-left: 1rem;
  }
  .ng-select-opened .ng-arrow-wrapper {
    background-image: url('/assets/images/search-grey.svg');
  }
}
