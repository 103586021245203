*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  // resetting for all devices
  font-size: 62.5% !important; //1 rem = 10px; 10px/16px = 62.5%
}

body {
  margin: 0; // 1
  font-size: 1.6rem;
  overflow: hidden;
  font-family: $trenda-normal;
  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: rgba($black, 0); // 4
}

.pointer {
  cursor: pointer;
}

a {
  text-decoration: none;
}

[hidden] {
  display: none !important;
}


[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  display: none;
}

::-webkit-input-placeholder { /* WebKit browsers */
  &::first-letter{
    text-transform: capitalize;
  }
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  &::first-letter{
    text-transform: capitalize;
  }
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  &::first-letter{
    text-transform: capitalize;
  }
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  &::first-letter{
    text-transform: capitalize;
  }
}
::placeholder {
  &::first-letter{
    text-transform: capitalize;
  }
}

.modal{
  background-color: rgb(0 0 0 / 39%) !important;
}

.tooltipClass{
  .tooltip-inner{
    background-color: $grey-blue !important;
    padding: .1rem .5rem;
    font-size: 1.2rem;
  }
}

.page-link{
  padding: .3rem 1.2rem;
  font-size: 1.4rem;
  border-color: transparent;
  background: #E0E4E9;
  color: #646F7B !important;
  margin-right: 0.7rem;
  border-radius: .4rem;
  font-family: $helvetica-normal;
}
.page-item.active .page-link{
  z-index: 3;
  border-color: $blue;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  color: $blue !important;
  padding: .3rem 1.2rem;
  font-size: 1.4rem;
  margin-right: 0.7rem;
  border-radius: .4rem;
  font-family: $helvetica-normal;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 4px;
}
.cdk-drag-placeholder	{
  opacity: 0;
}

.ng-select {
  .ng-select-container {
    min-height: 3.2rem;
    font-size: 1.6rem;
    height: auto;
  }
}

.ng-select {
  &.custom {
    min-width: 120px;
    .ng-select-container {
      border-radius: 0.4rem;
      min-height: 3.2rem;
      height: auto;
      border: solid 0.1rem #d3e2ea;
    }
  }
  &.font-size-12 {
    .ng-select-container {
      font-size: 1.2rem;
    }
  }
}

.ng-select.custom-width {
  width: 32.8rem;
}

.ng-select.ng-invalid.ng-dirty:not(.mobitable-select){
  .ng-select-container{
    border-color: #dc3545;
  }
  .ng-arrow-wrapper .ng-arrow{
    border-color: #dc3545 transparent transparent
  }
}
ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 1.6rem;
}
.ng-label-tmp{
  background-color: #E0E4E9 !important;
  color: $dark-grey!important;
  border-radius: .5rem;
}

.form-control, .form-check-label{
  font-size: 1.6rem;
}
.form-label, .form-check-label{
  font-size: 1.4rem;
  color: $dark-grey;
}
.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}
.invalid-feedback, .valid-feedback{
  font-size: 1.2rem;
}

.btn-action {
  font-weight: bold;
  font-size: 1.5rem;
  background: $blue;
  border-radius: 0.2rem;
  font-family: $trenda-normal;
  color: $white;
  text-transform: uppercase;
  &:hover{
    color: $white;
    background-color: darken($color: #168abd, $amount: 1);
  }
}

input:disabled {
  cursor: default;
  color: #999 !important;
}

.form-control::placeholder {
  color: #999;
  opacity: 1;
}

.disabled {
  pointer-events: none !important;
}

.not-allowed{
  cursor: not-allowed !important;
}

.red-star{
  color: red !important;
}

::-webkit-scrollbar {
  height: 1rem;
  width: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}

::-webkit-scrollbar-thumb {
  background: #C3C1C2;
  -webkit-border-radius: 1ex;
}
