@import "~bootstrap/scss/bootstrap";

@import "abstracts/variables";
// @import 'abstracts/mixins';
@import "abstracts/icons";
@import "abstracts/colors";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "base/reset";
@import "base/typography";
@import "override/bootstrap";
