/**********primary colors*********/
$white: #ffffff;
$black: #1e1e1e;
$grey: #8a96a4;
$light-grey: #c1cad4;
$dark-grey: #283648;
$grey-blue: #2c506d;
$blue: #0fa8ec;
$turquoise: #3ae2d8;
$green: #00d88d;
$dark-blue: #0c1c30;
$purple: #4d4084;
$light-purple: #edebf6;
$tulip: #e3a535;
$light-tulip: #f7c733;
$dark-tulip: #966f2a;
$cranberry: #da5d7a;
$warning: #440620;
$dark-slate-blue: #034C80;
$athens-gray: #F3F4F6;

/**********Font-family*********/
$helvetica-normal: "Helvetica-Regular";
$helvetica-bold: "Helvetica-Bold";
$helvetica-medium: "Helvetica-Medium";
$trenda-normal: "Trenda-Regular";
$trenda-bold: "Trenda-Bold";
$trenda-medium: "Trenda-Medium";

/***z-index***/
$header-index: 900;
$sidebar-index: 100;
$context-menu: 1030;
$modal-index: 1045;
$snackbar-index: 1060;
